<template>
  <div class="pageContent">
    <searchHeader />
    <div class="mallProductionDetails">
      <div class="proInfo">
        <el-row>
          <el-col :span="24">
            <div class="proImage">
              <p>商品图片</p>
            </div>
            <div class="proContent">
              <p class="title">示例商品</p>
              <div class="desc">
                <p class="descList">
                  <span class="descTitle">成交价：</span>
                  <span class="descText rushPrice">￥21.00</span>
                </p>
                <!-- <p class="descList marketPrice">市场价：￥25.00</p> -->
                <p class="descList">数量: 1</p>
                <p class="descList">履约订金: ￥4.2</p>
                <p class="descList">尾款: ￥16.8</p>
                <p class="descList">订单有效期至: 2020.3.10 12:00</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="address">
        <div class="addressTitle">
          <p class="text">送货地址</p>
          <p class="chooseAddress" @click="showAddressDialog()">选择送货地址</p>
        </div>
        <div class="addressDetails">
          <p>联系人：张明 联系方式：13211178912</p>
          <p>送货地址：天津市 东丽区 XXX小区 XXX楼</p>
        </div>
      </div>
      <div class="productTotal">
        <div class="proTotalText">
          <p>共1件商品，需支付剩余款￥16.8</p>
        </div>
        <div class="payButton">
          <el-button type="danger" @click="confirmPay()">确认付款</el-button>
        </div>
      </div>
      <!-- 选择地址弹窗 -->
      <el-dialog
        v-dialogDrag
        title="地址列表"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="400px"
      >
        <div class="addressList">
          <el-row>
            <el-col :span="24" class="addressItem">
              <el-radio v-model="checkedAddress" :label="1">
                联系人：张明 联系方式：13211178912
                <p class="addressText">天津市 东丽区 XX小区 XX楼</p>
              </el-radio>
            </el-col>
            <el-col :span="24" class="addressItem">
              <el-radio v-model="checkedAddress" :label="2">
                联系人：张明 联系方式：13211178912
                <p class="addressText">天津市 河北区 XX小区 XX楼</p>
              </el-radio>
            </el-col>
            <el-col :span="24" class="addressItem">
              <el-radio v-model="checkedAddress" :label="3">
                联系人：张明 联系方式：13211178912
                <p class="addressText">天津市 河西区 XX小区 XX楼</p>
              </el-radio>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="chooseAddress()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import searchHeader from "../base/header/searchHeader";
export default {
  data() {
    return {
      preCount: 1,
      dialogVisible: false,
      checkedAddress: 1
    };
  },
  components: {
    searchHeader
  },
  methods: {
    showAddressDialog() {
      this.dialogVisible = true;
    },
    chooseAddress() {
      this.dialogVisible = false;
    },
    confirmPay() {
      this.$alert("订单已支付成功", "支付成功", {
        confirmButtonText: "前往查看订单",
        callback: action => {
          this.$router.push("/mall/user/orderList");
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.mallProductionDetails {
  width: 1200px;
  // width: 80%;
  // min-width: 1300px;
  margin: 0 auto;
}
.mallProductionDetails .proInfo {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.proInfo {
  position: relative;
  overflow: hidden;
}
.proInfo .proImage {
  float: left;
  margin-right: 15px;
  width: 200px;
  height: 200px;
  background-color: #cccccc;
}
.proInfo .proImage p {
  line-height: 200px;
  text-align: center;
  color: #ffffff;
  font-size: 26px;
}
.proInfo .proContent {
  margin-left: 220px;
  line-height: 30px;
}
.proInfo .proContent .title {
  font-size: 18px;
  font-weight: 700;
}
.proInfo .proContent .desc {
  margin-top: 10px;
}
.proInfo .proContent .desc * {
  color: #666666;
}
.proInfo .proContent .desc .descList {
  overflow: hidden;
}
.proInfo .proContent .desc .descTitle {
  float: left;
}
.proInfo .proContent .desc .rushPrice,
.proInfo .proContent .desc .plusPrice {
  font-weight: 700;
  color: $commonThemeColor;
}
.proInfo .proContent .desc .marketPrice {
  text-decoration: line-through;
}
.address {
  margin-top: 20px;
  padding: 15px;
  line-height: 22px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.address .addressTitle {
  padding-bottom: 10px;
  border-bottom: 1px dashed #f2f2f2;
  overflow: hidden;
}
.address .addressTitle .text {
  float: left;
}
.address .addressTitle .chooseAddress {
  float: right;
  color: $commonThemeColor;
  cursor: pointer;
}
.address .addressDetails {
  padding-top: 10px;
}
.address .addressDetails * {
  color: #666666;
}
.productTotal {
  margin-top: 20px;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  overflow: hidden;
}
.productTotal .proTotalText {
  float: left;
  line-height: 40px;
}
.productTotal .payButton {
  float: right;
}
.addressItem {
  margin-bottom: 15px;
}
.addressText {
  margin-left: 28px;
  margin-top: 5px;
}
</style>
